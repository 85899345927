import React from 'react'

export default (props) => {
  // console.log({props})

  // if (!props.mark && !props.mark.file && !props.mark.file.asset && !props.mark.file.asset._ref) {
  //   return
  // }

  let linkText = props.children[0]
  let linkID = props.mark.file.asset._ref
  let linkURL = linkID.split('-')[1]
  let linkExt = linkID.split('-')[2]
  linkURL = `https://cdn.sanity.io/files/a29bfri3/production/${linkURL}.${linkExt}`
  return (
    <>
      {linkExt === 'pdf' && (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39 48' style={{width: '22px', bottom: '-8px', position: 'relative', display: 'inline-block', margin: '0 4px 0 0'}}>
          <defs />
          <g fillRule='nonzero' fill='none'>
            <path fill='#E1E6E9' d='M6.808 0L.054 6.792v35.145h30.578V0z' />
            <path d='M26.834 28.942c-.683-.785-1.682-1.329-2.81-1.532a25.838 25.838 0 00-5.073-.411c-1.45-1.472-2.851-3.21-3.808-5.12.837-2.775 1.28-5.176 1.528-6.528a2.443 2.443 0 00-.513-1.978 2.19 2.19 0 00-1.686-.808h-.001c-.629 0-1.163.259-1.545.747-1.278 1.637-.404 5.599-.216 6.378.193.79.468 1.559.805 2.304-.664 2.103-1.558 4.388-2.77 6.549-4.041 1.483-6.45 3.52-6.647 3.691-.671.657-1.003 1.316-.985 1.96.01.364.14.903.705 1.4.433.38.987.57 1.55.57a2.55 2.55 0 001.623-.586c1.951-1.615 3.515-3.619 4.77-5.747a23.912 23.912 0 016.603-1.272 37.23 37.23 0 004.719 3.867c1.194.825 2.791.718 3.798-.253.941-.91.924-2.117-.047-3.23zm-20.81 5.455c-.361.3-.892.32-1.206.044-.12-.106-.182-.203-.185-.289-.002-.07.03-.337.49-.79.034-.028 1.544-1.305 4.182-2.54-.94 1.318-2.025 2.536-3.28 3.575zm8.099-20.14c.078-.1.164-.162.35-.162.265 0 .437.16.516.256a.891.891 0 01.187.721c-.182.987-.47 2.554-.947 4.416a19.477 19.477 0 01-.042-.161c-.395-1.634-.676-4.287-.064-5.07zm-1.311 13.626a36.76 36.76 0 001.69-4.04 22.848 22.848 0 002.473 3.272c-1.5.147-2.892.42-4.164.768zm13.015 3.186c-.488.472-1.28.512-1.884.096a36.715 36.715 0 01-3.34-2.608c1.014.048 2.066.163 3.152.358.79.143 1.478.51 1.935 1.035.592.68.33.934.137 1.12z' fill='#E56353' />
            <path fill='#EBF0F3' d='M6.808 6.792V0L.054 6.792z' />
            <path d='M37.86 11.507H23.405c-.614 0-1.112-.5-1.112-1.118V4.164c0-.618.498-1.119 1.112-1.119H37.86c.614 0 1.112.501 1.112 1.119v6.225c0 .617-.498 1.118-1.112 1.118z' fill='#27A2DB' />
            <g fill='#EBF0F3'>
              <path d='M27.337 5.34c.324.273.487.694.487 1.262 0 .568-.166.984-.498 1.248-.33.264-.839.396-1.524.396h-.827V9.62h-.787v-4.69h1.6c.708 0 1.224.137 1.549.41zm-.557 1.936c.158-.168.236-.414.236-.738s-.1-.553-.3-.688c-.2-.134-.513-.2-.94-.2h-.801v1.878h.914c.436 0 .733-.084.89-.252zM32.28 5.545c.45.41.674.979.674 1.708s-.218 1.306-.654 1.73c-.436.426-1.103.638-2.001.638h-1.548v-4.69h1.601c.836 0 1.48.205 1.928.614zm-.12 1.728c0-1.074-.611-1.61-1.834-1.61h-.788v3.214h.874c.565 0 .998-.136 1.298-.407.3-.27.45-.67.45-1.197zM34.755 5.67v1.287h2.068v.732h-2.068v1.932h-.787v-4.69h3.11l-.008.738z' />
            </g>
            <g fill='#E56353'>
              <path d='M28.958 47.95L23 40.691h11.916z' />
              <path d='M25.571 34.5h6.857v6.65h-6.857z' />
            </g>
          </g>
        </svg>
      )}
      <a href={linkURL} target='_blank' rel='noopener noreferrer'>{linkText}</a>
    </>
  )
}
